import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput} from "@mui/material";
import {Checkbox, message, Modal, Table} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack, Close, DeleteOutline} from "@mui/icons-material";
import {
    createCourses, createLecture, deleteLecture, getCourseLectures, updateCourses, updateLecture, upload, uploadDocument
} from "../actions/apis";
import EditIcon from "@mui/icons-material/Edit";

function CourseDetail() {
    const navigate = useNavigate()
    const {courseId} = useParams();

    const columns = [{
        title: "Id", dataIndex: "id", render: (e) => {
            return e || "--"
        }
    }, {
        title: "Title", dataIndex: "title", render: (e) => {
            return e || "--"
        }
    }, {
        title: "Description", dataIndex: "description", render: (e) => {
            return e || "--"
        }
    }, {
        title: "Lecture Link", dataIndex: "link", render: (e) => {
            return e ? <a href={e} target={"_blank"}>View</a> : <span>No Video file attach</span>
        }
    }, {
        title: "Total Questions", dataIndex: "quiz", render: (e) => {
            return e ? e.length : 0
        }
    }, {
        title: "Quiz Submission", dataIndex: "submissions", render: (e, row) => {

            return <Button
                onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/dashboard/courses/${courseId}/lectures/${row.id}/submissions`)
                }}
                component="span"
                disabled={e && e?.length === 0}
                variant="outlined"
                style={{
                    color: '#E8B64D', border: '1px solid #E8B64D', fontSize: '0.8rem', textTransform: 'capitalize'
                }}
            >
                View
            </Button>
        }
    }, {
        title: "Action", dataIndex: "id", render: (e, row) => {
            return <>
                <IconButton onClick={() => {
                    setLectureDetails(row)
                    setEdit(true)
                    setVisible(true)
                }}>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={async () => {
                    const response = await deleteLecture({courseId, lectureId: e})
                    message.success(response.message);
                    await fetchLectures()
                }}>
                    <DeleteOutline/>
                </IconButton>
            </>
        }
    },]

    const paginationConfig = {
        pageSize: 5, showSizeChanger: false
    };

    const [state, setState] = useState({
        tableData: [], isLoading: true
    })

    useEffect(() => {
        fetchLectures()

    }, [])

    const fetchLectures = () => {
        getCourseLectures({courseId}).then(res => {
            console.log(res)
            setState((prevState) => ({
                ...prevState, tableData: res, isLoading: false
            }))
        })
    }

    const [visible, setVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [lectureDetails, setLectureDetails] = useState({
        title: "", description: "", link: "", quiz: []
    });

    const showModal = () => {
        setEdit(false)
        setVisible(true);
    };

    const handleOk = async () => {
        try {
            delete lectureDetails.lectureId;
            delete lectureDetails["submissions"];
            console.log(lectureDetails)
            if (Object.values(lectureDetails).every((e) => e)) {
                if (edit) {
                    await updateLecture({courseId, lectureId: lectureDetails.id, data: lectureDetails});
                } else {
                    await createLecture({courseId, data: lectureDetails})
                }
                setVisible(false);
                fetchLectures();
            } else {
                if (lectureDetails.title === "") {
                    message.error("Title is required")
                }
                if (lectureDetails.description === "") {
                    message.error("Description is required")
                }
                if (lectureDetails.link === "") {
                    message.error("Link is required")
                }
            }
        } catch (e) {
            setVisible(false);
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setLectureDetails({
            title: "", description: "", link: "", courseId, quiz: []
        })
    };

    const handleChange = (e) => {
        setLectureDetails((prevState) => ({...prevState, [e.target.name]: e.target.value}))
    }

    const handleImageChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        console.log(file.type);
        if (file && file.type.startsWith("video/")) {
            const formData = new FormData();
            formData.append('files', file);
            upload(formData)
                .then(async response => {
                    handleChange({
                        target: {
                            name: e.target.name, value: response[0]
                        }
                    })
                    e.target.value = null
                })
                .catch(error => {
                    console.error('Upload failed:', error);
                });
        }
    };


    return (<>
            {state.isLoading ? <div style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center'
            }}>
                <CircularProgress/>
            </div> : <div className="mainBody">
                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                }}>
                    <div style={{
                        display: 'flex', gap: '10px'
                    }}>
                        <IconButton onClick={() => {
                            navigate(-1);
                        }}>
                            <ArrowBack/>
                        </IconButton>
                        <p style={{
                            color: '#4CB8DB', fontSize: '25px', fontWeight: 'bold'
                        }}>
                            Lectures
                        </p>
                    </div>

                    <button style={{
                        outline: 'none',
                        background: "#4CB8DB",
                        border: "0",
                        color: 'white',
                        padding: "10px 15px ",
                        borderRadius: "7px"
                    }} onClick={showModal}>Create Lecture
                    </button>
                </div>
                <Table
                    style={{
                        overflow: "auto"
                    }}
                    columns={columns}
                    dataSource={state.tableData}
                    pagination={paginationConfig}
                    rowKey={"id"}
                    expandable={{
                        expandedRowRender: (record) => (record.quiz.map((data, index) => <p
                            style={{
                                margin: 0,
                            }}
                        >
                            <span style={{fontWeight: '600'}}>Question #{index + 1}</span> {data.question}
                        </p>)), rowExpandable: (record) => record.quiz != null && record.quiz.length > 0,
                    }}
                />
                <Modal
                    title={`${edit ? "Edit" : "Create"} Lecture`}
                    open={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText="Submit"
                    centered
                    styles={{
                        body: {
                            display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 0'
                        }
                    }}
                >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Title</InputLabel>
                        <OutlinedInput
                            required
                            type={'text'}
                            label="Title"
                            name={"title"}
                            value={lectureDetails.title}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Description</InputLabel>
                        <OutlinedInput
                            required
                            multiline={true}
                            minRows={4}
                            type={'text'}
                            label="Description"
                            name={"description"}
                            value={lectureDetails.description}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <input
                            required
                            type={'file'}
                            name={"link"}
                            accept="video/*"
                            onChange={handleImageChange}
                            style={{display: 'none'}}
                            id={`upload-image`}

                        />
                        <label htmlFor={`upload-image`}>
                            <Button
                                component="span"
                                variant="outlined"
                                style={{color: '#E8B64D', marginTop: '0.5rem', border: '1px solid #E8B64D'}}
                            >
                                Choose Video
                            </Button>
                        </label>
                    </FormControl>

                    <p style={{
                        textAlign: 'center', fontWeight: "800"
                    }}>-------------- QUIZ --------------</p>

                    {lectureDetails.quiz.map((data, index) => <div key={index} style={{
                        display: 'flex', gap: '10px', alignItems: 'center',
                    }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{`Question #${index + 1}`}</InputLabel>
                            <OutlinedInput
                                required
                                type={'text'}
                                label={`Question #${index + 1}`}
                                value={data.question}
                                onChange={(e) => {
                                    const temp = lectureDetails.quiz;
                                    temp[index].question = e.target.value;
                                    setLectureDetails((prevState) => ({
                                        ...prevState, quiz: temp
                                    }))
                                }}
                            />
                        </FormControl>
                        <IconButton onClick={() => {
                            const temp = lectureDetails.quiz;
                            temp.splice(index, 1);
                            setLectureDetails((prevState) => ({
                                ...prevState, quiz: temp
                            }))
                        }}>
                            <Close/>
                        </IconButton>
                    </div>)}
                    <Button
                        component="span"
                        variant="outlined"
                        style={{color: '#E8B64D', marginTop: '0.5rem', border: '1px solid #E8B64D'}}
                        onClick={() => {
                            setLectureDetails((prevState) => ({
                                ...prevState, quiz: [...prevState.quiz, {
                                    question: ""
                                }]
                            }))
                        }}
                    >
                        Add Question
                    </Button>
                </Modal>
            </div>}
        </>);
}

export default CourseDetail;