import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput} from "@mui/material";
import {Checkbox, message, Modal, Table} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack, DeleteOutline} from "@mui/icons-material";
import {
    createCourses,
    createLecture, deleteLecture, getChecklistById,
    getCourseLectures, getSubmissions,
    updateCourses,
    updateLecture,
    upload,
    uploadDocument
} from "../actions/apis";
import EditIcon from "@mui/icons-material/Edit";

function CheckListSubmission() {
    const navigate = useNavigate()
    const {checkId} = useParams();

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Name",
            dataIndex: "user",
            render: (e) => {
                return e?.name || "--"
            }
        },
        {
            title: "Email",
            dataIndex: "user",
            render: (e) => {
                return e?.email || "--"
            }
        },
        {
            title: "Submission Date",
            dataIndex: "submissionDate",
            render: (e) => {
                return e ? new Date(e).toLocaleString() : "Not Submit yet"
            }
        },
    ]

    const paginationConfig = {
        pageSize: 5,
        showSizeChanger: false
    };

    const [state, setState] = useState({
        tableData: [],
        isLoading: true
    })

    useEffect(() => {
        fetchCheckList()
    }, [])

    const fetchCheckList = () => {
        getSubmissions(checkId).then(res => {
            setState((prevState) => ({
                ...prevState,
                tableData: res,
                isLoading: false
            }))
        })
    }


    return (
        <>
            {
                state.isLoading ?
                    <div style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center'
                    }}>
                        <CircularProgress/>
                    </div>
                    :
                    <div className="mainBody">
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                display: 'flex',
                                gap: '10px'
                            }}>
                                <IconButton onClick={() => {
                                    navigate(-1);
                                }}>
                                    <ArrowBack/>
                                </IconButton>
                                <p style={{
                                    color: '#4CB8DB',
                                    fontSize: '25px',
                                    fontWeight: 'bold'
                                }}>
                                    Submissions
                                </p>
                            </div>
                        </div>
                        <Table
                            style={{
                                overflow: "auto"
                            }}
                            columns={columns}
                            dataSource={state.tableData}
                            pagination={paginationConfig}
                            rowKey={"id"}
                            expandable={{
                                expandedRowRender: (record) => (
                                    (record?.checkList ?? []).map((data, index) => <div
                                            style={{
                                                margin: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                                borderBottom: '1px solid #ddd'
                                            }}
                                        >
                                            <span style={{fontWeight: '600'}}>#{index + 1}</span>
                                            <p>{data.label}</p>
                                            <Checkbox disabled={true} className="checkbox-green" checked={data.green}/>
                                            <Checkbox disabled={true} className={"checkbox-yellow"} checked={data.yellow}/>
                                            <Checkbox disabled={true} className={"checkbox-red"} checked={data.red}/>
                                        </div>
                                    )),
                                rowExpandable: (record) => record.submissionDate != null,
                            }}
                        />
                    </div>
            }
        </>
    );
}

export default CheckListSubmission;