import React from 'react';
import {Card, Icon} from "@mui/material";
import {ReactComponent as Logo} from "../assets/rarebreedlogo.svg";
import LogoutIcon from "@mui/icons-material/Logout";

function Headers() {
    return (
        <div style={{padding: '0.5rem'}}>
            <Card className={'navbar'}>
                <Logo className={'navbarLogo'}/>
                <Icon onClick={() => {
                    window.localStorage.removeItem('adminToken')
                    window.dispatchEvent(new Event('storage'))
                }}>
                    <LogoutIcon style={{color: 'white', cursor: 'pointer'}}/>
                </Icon>
            </Card>
        </div>
    );
}

export default Headers;