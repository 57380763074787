 import './App.css';
import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import privateRoutes from "./routes/private.routes";
import publicRoutes from "./routes/public.routes";

function App() {
	const [token, setToken] = useState(false)
	const router = createBrowserRouter([token ? privateRoutes() : {}, ...publicRoutes()])

	useEffect(() => {
		window.addEventListener('storage', () => {
			console.log("Change to local storage!");
			setToken(true)
		})
		return () => {
			window.removeEventListener('storage', () => {
				console.log("successfully removed")
			})
		}
	}, []);
	return (<RouterProvider router={router}/>)
}

export default App;
