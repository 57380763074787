import React, {useEffect, useState} from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {
    AttachMoney,
    Cancel,
    CurrencyExchange,
    DoneAll,
    FireTruck,
    Paid,
    Person,
    ShoppingCart
} from "@mui/icons-material";
import "./Dashboard.scss"
import DashboardCard from "../components/DashboardCard";
import {dashboard} from "../actions/apis";
import {Line} from "react-chartjs-2";

import bglogo from "../assets/rarebreediconlg.webp";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);
export const options = {
    responsive: true, plugins: {
        legend: {
            position: 'top',
        }, title: {
            display: false, text: 'Order ',
        },
    }
}

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


function Dashboard() {
    // const [data, setData] = useState({
    //     orders: [],
    //     totalAmount: 0,
    //     pendingAmount: 0,
    //     totalOrders: 0,
    //     pendingOrders: 0,
    //     inProgressOrders: 0,
    //     acceptOrders: 0,
    //     rejectOrders: 0,
    //     completeOrders: 0,
    //     user: 0,
    //     trucks: 0,
    // })
    // useEffect(() => {
    //     dashboard().then((response) => setData(response))
    // }, []);

    return (
        <div className={"dashboardContainer"} style={{
            display: 'flex', flexDirection: 'column', gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }}>
            {/*<div style={{*/}
            {/*    display: 'flex', flexDirection: 'row',*/}
            {/*    flexWrap: 'wrap',*/}
            {/*    gap: '10px',*/}
            {/*    margin: '10px',*/}
            {/*}}>*/}
            {/*    <DashboardCard Icon={Person} title={"No Of users"} amount={data.user} path={"users"}/>*/}
            {/*    <DashboardCard Icon={Person} title={"No Of courses"} amount={data.user} path={"users"}/>*/}
            {/*    <DashboardCard Icon={Person} title={"No Of lecturess"} amount={data.user} path={"users"}/>*/}
            {/*    <DashboardCard Icon={Person} title={"No Of checklist"} amount={data.user} path={"users"}/>*/}
            {/*</div>*/}
            {/*<h1>Course progress</h1>*/}
            {/*<div style={{*/}
            {/*    width: '80%',*/}
            {/*    alignSelf: 'center'*/}
            {/*}}>*/}

            {/*    <Line data={{*/}
            {/*        labels,*/}
            {/*        datasets: [*/}
            {/*            {*/}
            {/*                fill: false,*/}
            {/*                label: 'Course progress',*/}
            {/*                data: data.orders || [],*/}
            {/*                borderColor: 'rgb(53, 162, 235)',*/}
            {/*                backgroundColor: 'rgba(53, 162, 235, 0.5)',*/}
            {/*            }*/}
            {/*        ],*/}
            {/*    }} options={options}/>*/}
            {/*</div>*/}
            <h1>Welcome to Rare Breed Tools</h1>
            <img src={bglogo} alt="Background Logo" className="dashboard-image"/>
        </div>);
}

export default Dashboard;