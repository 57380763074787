import { Outlet } from "react-router-dom";
import React, { Suspense } from "react";
import Headers from "../components/Headers";
import { CircularProgress } from "@mui/material";
import Sidebar from "../components/Sidebar";

const Layout = () => {
	return (<div style={{
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
	}}>
		<Headers/>
		<Suspense fallback={<CircularProgress size={24} style={{
			color: "#4CB8DB"
		}}/>}>
			<div style={{
				flex: 1, display: 'flex', gap: '20px', padding: '10px',
				height: '100%',
			}}>
				<Sidebar/>
				<div style={{
					flex: 6,
					height: '100%',
				}}>
					<Outlet/>
				</div>
			</div>
		</Suspense>
	</div>);
}

export default Layout;