import {Button, CircularProgress} from '@mui/material';
import {Table} from 'antd';
import React, {useEffect, useState} from 'react';
import '../App.css';
import {useNavigate, useParams} from "react-router-dom";
import {getDocument, upload, uploadDocument} from "../actions/apis";

function Documents() {

    const handleImageChange = (e, id) => {
        e.preventDefault();
        console.log(id);
        const file = e.target.files[0];
        console.log(file.type);
        if (file && file.type === 'application/pdf') {
            const formData = new FormData();
            formData.append('files', file);
            upload(formData)
                .then(async response => {
                    const res = await uploadDocument(id, response[0]);
                    fetchDoc();
                })
                .catch(error => {
                    console.error('Upload failed:', error);
                });
        }
    };


    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Title",
            dataIndex: "title",
            render: (e) => {
                return <span style={{
                    textTransform: 'capitalize'
                }}>{e}</span>
            }
        },
        {
            title: "PDF LINK",
            dataIndex: "link",
            render: (e) => {
                return e ? <a href={e} target={"_blank"}>View</a> : <span>No PDF file attach</span>
            }
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (e) => {
                return <>
                    <input
                        type={'file'}
                        accept="application/pdf"
                        onChange={(event) => handleImageChange(event, e)}
                        style={{display: 'none'}}
                        id={`upload-image-${e}`}
                    />
                    <label htmlFor={`upload-image-${e}`}>
                        <Button
                            component="span"
                            variant="outlined"
                            style={{color: '#E8B64D', marginTop: '0.5rem', border: '1px solid #E8B64D'}}
                        >
                            Choose PDF
                        </Button>
                    </label>
                </>
            }
        },
    ]

    const paginationConfig = {
        pageSize: 10,
        showSizeChanger: false
    };

    const [state, setState] = useState({
        tableData: [],
        isLoading: true
    })
    useEffect(() => {
        fetchDoc()
    }, [])

    const fetchDoc = () => {
        getDocument().then(res => {
            setState({
                ...state,
                tableData: res,
                isLoading: false
            })
        });

    }


    return (
        <>
            {
                state.isLoading ?
                    <div style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center'
                    }}>
                        <CircularProgress/>
                    </div>
                    :
                    <div className="mainBody">
                        <div style={{
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: 'center'
                        }}>
                            <p style={{
                                color: '#4CB8DB',
                                fontSize: '25px',
                                fontWeight: 'bold'
                            }}>
                                Documents
                            </p>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={state.tableData}
                            pagination={paginationConfig}
                        />
                    </div>
            }
        </>
    );
}

export default Documents;
