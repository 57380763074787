import {
    CircularProgress,
    FormControl,
    FormHelperText, IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select
} from '@mui/material';
import {message, Modal, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import '../App.css';
import {allUsers, createCourses, createUser, updateCourses, updateUsers} from '../actions/apis';
import {useNavigate, useParams} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {ArrowForwardIosOutlined, BlockOutlined, Done, PasswordOutlined} from "@mui/icons-material";

const initialData = {
    name: "", email: "", phone: "", password: "", role: "",
}

function Users() {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const columns = [
        {
            title: "Id", dataIndex: "id", render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Name", dataIndex: "name",
        },
        {
            title: "Email", dataIndex: "email",
        },
        {
            title: "Contact Number", dataIndex: "phone",
        },
        {
            title: "Type", dataIndex: "role",
        },
        {
            title: "Last Login", dataIndex: "lastLogin",
            render: (e) => {
                return e ? new Date(e).toLocaleString() : "---"
            }
        },
        {
            title: "Created At", dataIndex: "createdAt",
            render: (e) => {
                return new Date(e).toLocaleString()
            }
        },
        {
            title: "Updated At", dataIndex: "updatedAt",
            render: (e) => {
                return new Date(e).toLocaleString()
            }
        },
        {
            title: "Action", dataIndex: "id", render: (e, row) => {
                return <>
                    <IconButton onClick={() => {
                        setUserDetails(row)
                        setEdit(true)
                        setVisible(true)
                    }}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => {
                        setUserDetails(row)
                        setUpdatePassword(true)
                    }}>
                        <PasswordOutlined/>
                    </IconButton>
                    <IconButton onClick={async () => {
                        await updateUsers({
                            userId: row.id,
                            data: {
                                suspended: !row["suspended"],
                            }
                        })
                        fetchUsers();
                    }}>
                        {row.suspended ? <Done/> : <BlockOutlined/>}
                    </IconButton>
                </>
            },
        }
    ]

    const paginationConfig = {
        pageSize: 5, showSizeChanger: false
    };

    const [state, setState] = useState({
        tableData: [], isLoading: true
    })

    const [userDetails, setUserDetails] = useState({
        ...initialData
    })

    const showModal = () => {
        setEdit(false)
        setVisible(true);
    };

    const handleOk = async () => {
        try {
            if (Object.keys(initialData).every((e) => userDetails[e] || (edit && !userDetails["password"]))) {
                if (edit) {
                    delete userDetails.password;
                    delete userDetails["updatedAt"];
                    delete userDetails["createdAt"];
                    await updateUsers({userId: userDetails.id, data: userDetails});
                } else {
                    await createUser({data: userDetails})
                }
                setUserDetails({
                    ...initialData
                })
                setVisible(false);
                fetchUsers();
            } else {
                message.error("All fields are required")

            }
        } catch (e) {
            message.error(`Unable to ${edit ? "Update" : "Create"} user`)
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setUpdatePassword(false)
        setUserDetails({
            ...initialData
        })
    };

    useEffect(() => {
        fetchUsers()
    }, [])


    const fetchUsers = () => {
        allUsers().then(res => {
            setState({
                ...state, tableData: res, isLoading: false
            })
        });

    }

    const changeHandler = (e) => {
        setUserDetails((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    function generateRandomPassword(length = 12) {
        const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const digits = '0123456789';
        const specialCharacters = '!@#$%^&*()_+-=[]{}|;:,.<>?';

        const allChars = upperCaseLetters + lowerCaseLetters + digits + specialCharacters;
        let password = '';

        password += upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
        password += lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];
        password += digits[Math.floor(Math.random() * digits.length)];
        password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        for (let i = password.length; i < length; i++) {
            password += allChars[Math.floor(Math.random() * allChars.length)];
        }

        return password.split('').sort(() => 0.5 - Math.random()).join('');
    }


    return (<>
        {state.isLoading ? <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center'
        }}>
            <CircularProgress/>
        </div> : <div className="mainBody">
            <div style={{
                display: 'flex', justifyContent: "space-between", alignItems: 'center'
            }}>
                <p style={{
                    color: '#4CB8DB', fontSize: '25px', fontWeight: 'bold'
                }}>
                    Users
                </p>
                <button style={{
                    outline: 'none',
                    background: "#4CB8DB",
                    border: "0",
                    color: 'white',
                    padding: "10px 15px ",
                    borderRadius: "7px"
                }} onClick={showModal}>
                    Create User
                </button>
            </div>
            <Table
                columns={columns}
                dataSource={state.tableData}
                pagination={paginationConfig}
                rowClassName={(record, index) => {
                    return record["suspended"] ? 'suspendedUser' : '';
                }}
            />
        </div>}
        <Modal
            title={`${edit ? "Edit" : "Create"} User`}
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Submit"
            centered
            styles={{
                body: {
                    display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 0'
                }
            }}
        >
            <FormControl fullWidth variant="outlined">
                <InputLabel>Name</InputLabel>
                <OutlinedInput
                    type={'text'}
                    label="Name"
                    name={"name"}
                    value={userDetails.name}
                    onChange={changeHandler}
                />
            </FormControl>
            <FormControl fullWidth variant="outlined">
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                    type={'email'}
                    label="Email"
                    name={"email"}
                    value={userDetails.email}
                    onChange={changeHandler}
                />
            </FormControl>
            <FormControl fullWidth variant="outlined">
                <InputLabel>Phone</InputLabel>
                <OutlinedInput
                    type={'number'}
                    label="Phone"
                    name={"phone"}
                    value={userDetails.phone}
                    onChange={changeHandler}
                />
            </FormControl>
            {!edit ?
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                }}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Password</InputLabel>
                        <OutlinedInput
                            type={'text'}
                            label="Password"
                            name={"password"}
                            value={userDetails.password ?? ""}
                            onChange={changeHandler}
                        />
                        <FormHelperText>Make sure to copy and save it </FormHelperText>
                    </FormControl>
                    <button style={{
                        flex: 1,
                        outline: 'none',
                        background: "#4CB8DB",
                        border: "0",
                        color: 'white',
                        padding: "10px 15px ",
                        borderRadius: "7px",
                        fontSize: '12px',
                        cursor: 'pointer'
                    }} onClick={() => {
                        let password = generateRandomPassword();
                        setUserDetails((prevState) => ({
                            ...prevState,
                            password,
                        }));
                    }}>Generate
                    </button>
                </div> : <></>
            }
            <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                    value={userDetails.role}
                    label="Type"
                    placeholder={"Select user type"}
                    onChange={(e) => {
                        setUserDetails((prevState) => ({
                            ...prevState,
                            role: e.target.value
                        }))
                    }}
                >
                    <MenuItem value={"normal"}>Normal</MenuItem>
                    <MenuItem value={"membership"}>Membership</MenuItem>
                </Select>
            </FormControl>
        </Modal>
        <Modal
            title={`Update Password`}
            open={updatePassword}
            onOk={async () => {
                try {
                    await updateUsers({
                        userId: userDetails.id, data: {
                            password: userDetails.password
                        }
                    });
                    setUpdatePassword(false);
                    setUserDetails({...initialData})
                    fetchUsers();
                } catch (e) {
                    message.error("Unable to update password");
                }
            }}
            onCancel={handleCancel}
            okText="Submit"
            centered
            styles={{
                body: {
                    display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 0'
                }
            }}
        >
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
            }}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Password</InputLabel>
                    <OutlinedInput
                        type={'text'}
                        label="Password"
                        name={"password"}
                        value={userDetails.password ?? ""}
                        onChange={changeHandler}
                    />
                    <FormHelperText>Make sure to copy and save it </FormHelperText>
                </FormControl>
                <button style={{
                    flex: 1,
                    outline: 'none',
                    background: "#4CB8DB",
                    border: "0",
                    color: 'white',
                    padding: "10px 15px ",
                    borderRadius: "7px",
                    fontSize: '12px',
                    cursor: 'pointer'
                }} onClick={() => {
                    let password = generateRandomPassword();
                    setUserDetails((prevState) => ({
                        ...prevState,
                        password,
                    }));
                }}>Generate
                </button>
            </div>
        </Modal>
    </>);
}

export default Users;
