import Login from "../pages/Login";

export default () => {
	return (
	  [
		  {
			  path: '/', element: <Login/>
		  },
		  {
			  path: '*', element: <Login/>
		  }
	  ]
	)
}