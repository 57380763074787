import {VisibilityOff, Visibility, Email, Lock} from '@mui/icons-material';
import {
    FormControl, OutlinedInput, IconButton, InputLabel, InputAdornment, Button, CircularProgress
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {auth} from '../actions/apis';
import background from '../assets/rarebreediconlg.webp'
import {ReactComponent as Logo} from "../assets/rarebreedlogo.svg";
import '../App.css'
import {useNavigate} from "react-router-dom";

function Login() {
    const navigate = useNavigate();

    const [state, setState] = useState({
        showPassword: false, email: '', password: '', token: '', isLoading: false
    })
    const handleClickShowPassword = () => setState({...state, showPassword: !state.showPassword});
    return (<div className={'login'}>
        <div className={'loginForm'}>
            <Logo/>
            <p>LOGIN</p>

            <FormControl fullWidth sx={{m: 1, width: '25ch'}} variant="outlined">
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                    type={'text'}
                    label="Email"
                    startAdornment={<InputAdornment position="start">
                        <Email/>
                    </InputAdornment>}
                    onChange={(e) => {
                        setState({
                            ...state, email: e.target.value
                        })
                    }}
                />
            </FormControl>


            <FormControl fullWidth sx={{m: 1, width: '25ch'}} variant="outlined">
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                    type={state.showPassword ? 'text' : 'password'}
                    startAdornment={<InputAdornment position="start">
                        <Lock/>
                    </InputAdornment>}
                    endAdornment={<InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {state.showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>}
                    label="Password"
                    onChange={(e) => {
                        setState({
                            ...state, password: e.target.value
                        })
                    }}
                />
            </FormControl>

            {<Button
                style={{backgroundColor: '#006B9B', marginTop: '2rem', width: '250px', padding: '10px'}}
                variant="contained"
                onClick={async () => {
                    if (!state.isLoading) {
                        setState({
                            ...state, isLoading: true
                        })
                        auth(state.email, state.password).then((res) => {
                            setState({
                                ...state, isLoading: false
                            })
                            navigate("/dashboard")
                        })
                    }
                }}
            >
                {state.isLoading ? <CircularProgress size={24} style={{
                    color: "white"
                }}/> : "Log In"}
            </Button>}
        </div>
    </div>);
}

export default Login;