import React from 'react';
import {
    Book,
    Dashboard,
    DriveFileMove,
    FileOpen,
    FireTruck,
    Person,
    ShoppingCart,
    Task,
    TaskAlt
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

function Sidebar() {
    const navigate = useNavigate()
    const selectedTab = (path) => {
        try {
            const currentPath = window.location.pathname;
            console.log(currentPath);
            return currentPath === path;
        } catch (e) {
            return false;
        }
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem 1rem',
            flex: 1,
            background: '#00224D',
            boxShadow: "0px 1px 5px #00224D",
            borderRadius: '5px',
            gap: '10px',
        }}>
            {/*<div*/}
            {/*    className={"sidebarRow"}*/}
            {/*    onClick={() => {*/}
            {/*        navigate("/dashboard")*/}
            {/*    }}*/}
            {/*    style={{*/}
            {/*        background: selectedTab("/dashboard") ? 'linear-gradient(195deg,  #4CB8DB, #4CB8DB)' : 'transparent'*/}
            {/*    }}>*/}
            {/*    <Dashboard className={'sidebarIcon'}/>*/}
            {/*    <span>Dashboard</span>*/}
            {/*</div>*/}
            <div
                className={"sidebarRow"}
                onClick={() => {
                    navigate("/dashboard/users")
                }}
                style={{
                    background: selectedTab("/dashboard/users") ? 'linear-gradient(195deg,  #4CB8DB, #4CB8DB)' : 'transparent'
                }}>
                <Person className={'sidebarIcon'}/>
                <span>Users</span>
            </div>
            <div
                className={"sidebarRow"}
                onClick={() => {
                    navigate("/dashboard/courses")
                }}
                style={{
                    background: selectedTab("/dashboard/courses") ? 'linear-gradient(195deg,  #4CB8DB, #4CB8DB)' : 'transparent'
                }}>
                <Book className={'sidebarIcon'}/>
                <span>Courses</span>
            </div>
            <div
                className={"sidebarRow"}
                onClick={() => {
                    navigate("/dashboard/documents")
                }}
                style={{
                    background: selectedTab("/dashboard/documents") ? 'linear-gradient(195deg,  #4CB8DB, #4CB8DB)' : 'transparent'
                }}>
                <DriveFileMove className={'sidebarIcon'}/>
                <span>Documents</span>
            </div>
            <div
                className={"sidebarRow"}
                onClick={() => {
                    navigate("/dashboard/checkList")
                }}
                style={{
                    background: selectedTab("/dashboard/checkList") ? 'linear-gradient(195deg,  #4CB8DB, #4CB8DB)' : 'transparent'
                }}>
                <TaskAlt className={'sidebarIcon'}/>
                <span>CheckList</span>
            </div>

        </div>

    );
}

export default Sidebar;