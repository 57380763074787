import React, {useEffect, useState} from 'react';
import {Button, Card, CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput} from "@mui/material";
import {Checkbox, message, Modal, Select, Space, Table} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {
    ArrowBack, Close, CurrencyExchangeOutlined, DeleteOutline, SwapCallsOutlined, SwapVerticalCircleOutlined
} from "@mui/icons-material";
import {
    allUsers,
    assignChecklist,
    createChecklist,
    createCourses,
    createLecture,
    deleteChecklist,
    deleteLecture,
    getChecklist,
    getCourseLectures,
    updateChecklist,
    updateCourses,
    updateLecture,
    upload,
    uploadDocument
} from "../actions/apis";
import EditIcon from "@mui/icons-material/Edit";


const initData = {
    title: "", checkList: [], assigned: []
}

function CheckList() {
    const navigate = useNavigate()

    const [state, setState] = useState({
        tableData: null, isLoading: true
    })

    useEffect(() => {
        fetchTasks()
    }, [])

    const fetchTasks = () => {
        getChecklist().then(res => {
            setState((prevState) => ({
                ...prevState, tableData: res, isLoading: false
            }))
        })
    }

    const [visible, setVisible] = useState(false);
    const [taskDetails, setTaskDetails] = useState({
        ...initData
    });

    const handleOk = async () => {
        try {
            if (Object.values(taskDetails).every((e) => e)) {
                await updateChecklist({id: taskDetails.id, data: taskDetails});
                setVisible(false);
                setTaskDetails({...initData})
                fetchTasks();
            } else {
                if (taskDetails.title === "") {
                    message.error("Title is required")
                }
                if (taskDetails.checkList.length === 0) {
                    message.error("CheckList is required")
                }
            }
        } catch (e) {
            message.error(e?.message ?? "Server error")
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setTaskDetails({
            ...initData
        })
    };

    const handleChange = (e) => {
        setTaskDetails((prevState) => ({...prevState, [e.target.name]: e.target.value}))
    }

    return (<>
        {state.isLoading ? <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center'
        }}>
            <CircularProgress/>
        </div> : <div className="mainBody">
            <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between'
            }}>
                <p style={{
                    color: '#4CB8DB', fontSize: '25px', fontWeight: 'bold'
                }}>
                    {state.tableData?.title ?? "CheckList"}
                </p>
                <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    <button style={{
                        outline: 'none',
                        background: "#4CB8DB",
                        border: "0",
                        color: 'white',
                        padding: "10px 15px ",
                        borderRadius: "7px"
                    }} onClick={(e) => {
                        setTaskDetails(state.tableData);
                        setVisible(true);
                    }}>
                        Edit
                    </button>
                    <button style={{
                        outline: 'none',
                        background: "#4CB8DB",
                        border: "0",
                        color: 'white',
                        padding: "10px 15px ",
                        borderRadius: "7px"
                    }} onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/dashboard/checkList/${state.tableData.id}/submissions`)
                    }}>View submission
                    </button>
                </div>
            </div>
            <div style={{
                display: 'grid',
                gap: '10px',
                gridTemplateColumns: "repeat(2,1fr)"
            }}>

                {(state.tableData?.checkList ?? []).map((element, index) => <Card
                    style={{
                        padding: '10px',
                    }}
                    title={element.label}>
                    {element.label}
                </Card>)}
            </div>
            <Modal
                title={`Edit Task`}
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Submit"
                centered
                styles={{
                    body: {
                        display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 0'
                    }
                }}
            >
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Title</InputLabel>
                    <OutlinedInput
                        required
                        type={'text'}
                        label="Title"
                        name={"title"}
                        value={taskDetails.title}
                        onChange={handleChange}
                    />
                </FormControl>
                <p style={{
                    textAlign: 'center', fontWeight: "800"
                }}>-------------- CHECKLIST --------------</p>

                {taskDetails.checkList.map((data, index) => <div style={{
                    display: 'flex', gap: '10px', alignItems: 'center',
                }}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>{`Task #${index + 1}`}</InputLabel>
                        <OutlinedInput
                            required
                            type={'text'}
                            label={`Task #${index + 1}`}
                            value={data.label}
                            onChange={(e) => {
                                const temp = taskDetails.checkList;
                                temp[index].task = e.target.value;
                                setTaskDetails((prevState) => ({
                                    ...prevState, checkList: temp
                                }))
                            }}
                        />
                    </FormControl>
                    <IconButton onClick={() => {
                        const temp = taskDetails.checkList;
                        temp.splice(index, 1);
                        setTaskDetails((prevState) => ({
                            ...prevState, checkList: temp
                        }))
                    }}>
                        <Close/>
                    </IconButton>
                </div>)}
                <Button
                    component="span"
                    variant="outlined"
                    style={{color: '#E8B64D', marginTop: '0.5rem', border: '1px solid #E8B64D'}}
                    onClick={() => {
                        setTaskDetails((prevState) => ({
                            ...prevState, checkList: [...prevState.checkList, {
                                task: ""
                            }]
                        }))
                    }}
                >
                    Add Task
                </Button>
            </Modal>

        </div>}
    </>);
}

export default CheckList;