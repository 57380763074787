import axios from 'axios';


const LOCAL = 'https://rarebreedtools.com/v1/api'

const AxiosCall = axios.create({
    baseURL: LOCAL,
});

AxiosCall.interceptors.request.use((request) => {
    document.querySelector('#loaderContainer').style.display = 'block';
    return request;
});

AxiosCall.interceptors.response.use(
    (response) => {
        document.querySelector('#loaderContainer').style.display = 'none';
        return response;
    },
    (error) => {
        document.querySelector('#loaderContainer').style.display = 'none';
        return Promise.reject(error);
    }
);


export const auth = (email, password) => {
    const data = {email, password}
    return AxiosCall.post(`/auth/login/admin`, data).then((response) => {
        localStorage.setItem('adminToken', response?.data?.data?.accessToken)
        window.dispatchEvent(new Event('storage'))
    }).catch(error => {
        console.log(error)
    })
};

export const allUsers = () => {
    return AxiosCall.get(`/users`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const dashboard = () => {
    return AxiosCall.get(`/dashboard`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const upload = (data) => {
    return AxiosCall.post(`/upload`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    })
};


export const getDocument = () => {
    return AxiosCall.get(`/documents`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const uploadDocument = (id, link) => {
    return AxiosCall.put(`/documents/${id}`, {
        link
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
    })
};


export const getAllCourses = () => {
    return AxiosCall.get(`/courses`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const createCourses = ({data}) => {
    return AxiosCall.post(`/courses`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const updateCourses = ({courseId, data}) => {
    return AxiosCall.put(`/courses/${courseId}`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const deleteCourses = ({courseId}) => {
    return AxiosCall.delete(`/courses/${courseId}`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const getCourseLectures = ({courseId}) => {
    return AxiosCall.get(`/courses/${courseId}/lectures`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const createLecture = ({courseId, data}) => {
    return AxiosCall.post(`/courses/${courseId}/lectures`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const updateLecture = ({courseId, lectureId, data}) => {
    return AxiosCall.put(`/courses/${courseId}/lectures/${lectureId}`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const deleteLecture = ({courseId, lectureId}) => {
    return AxiosCall.delete(`/courses/${courseId}/lectures/${lectureId}`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const getChecklist = () => {
    return AxiosCall.get(`/tasks`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const getChecklistById = (taskId) => {
    return AxiosCall.get(`/tasks/${taskId}`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const getSubmissions = (taskId) => {
    return AxiosCall.get(`/tasks/submission/${taskId}`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const createChecklist = ({data}) => {
    return AxiosCall.post(`/tasks`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const assignChecklist = ({taskId, assigned}) => {
    return AxiosCall.post(`/tasks/assign/${taskId}`, {assigned}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const updateChecklist = ({id, data}) => {
    return AxiosCall.put(`/tasks/${id}`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const deleteChecklist = ({id}) => {
    return AxiosCall.delete(`/tasks/${id}`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const createUser = ({data}) => {
    return AxiosCall.post(`/users`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const updateUsers = ({userId, data}) => {
    return AxiosCall.put(`/users/${userId}`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};

export const deleteUser = ({userId}) => {
    return AxiosCall.delete(`/users/${userId}`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`},
    }).then((response) => {
        return response.data.data
    }).catch(error => {
        console.log(error)
        return []
    })
};