import React, {useEffect, useState} from 'react';
import {CircularProgress, IconButton} from "@mui/material";
import {Table} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";

function UserDetail() {
    const navigate = useNavigate()
    const {id} = useParams();

    const columns = [
        {
            title: "Order Id",
            dataIndex: "_id",
            render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Delivery Address",
            dataIndex: "deliveryAddress",
            render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Delivery Type",
            dataIndex: "serviceType",
            render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Order Status",
            dataIndex: "status",
            render: (e) => {
                return e === 0 ? "Pending" : e === 1 ? "CheckOut" : e === 2 ? "Accept" : e === 3 ? "Processing" : e === 4 ? "Done" : e === -1 ? "Reject" : "--"
            }
        },
        {
            title: "Delivery Fee",
            dataIndex: "deliveryFee",
            render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Price",
            dataIndex: "price",
            render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Total Gst",
            dataIndex: "totalGst",
            render: (e) => {
                return e || "--"
            }
        },
        {
            title: "Truck Id",
            dataIndex: "truckId",
            render: (e) => {
                return e._id || "--"
            }
        },
        {
            title: "Truck Name",
            dataIndex: "truckId",
            render: (e) => {
                return e.name || "--"
            }
        },
        {
            title: "Driver Name",
            dataIndex: "truckId",
            render: (e) => {
                return e.driver.firstName || "--"
            }
        },
        {
            title: "Driver Contact",
            dataIndex: "truckId",
            render: (e) => {
                return e.driver.contactNo || "--"
            }
        },
        {
            title: "Driver Email",
            dataIndex: "truckId",
            render: (e) => {
                return e.driver.email || "--"
            }
        },
        {
            title: "Truck Address",
            dataIndex: "truckId",
            render: (e) => {
                return e.driver.address || "--"
            }
        },
    ]

    const paginationConfig = {
        pageSize: 5,
        showSizeChanger: false
    };

    const [state, setState] = useState({
        tableData: [],
        isLoading: true
    })

    useEffect(() => {
        // userOrders(id).then(res => {
        //     setState({
        //         ...state,
        //         tableData: res,
        //         isLoading: false
        //     })
        // });

    }, [])
    return (
        <>
            {
                state.isLoading ?
                    <div style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center'
                    }}>
                        <CircularProgress/>
                    </div>
                    :
                    <div className="mainBody">
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <IconButton onClick={() => {
                                navigate(-1);
                            }}>
                                <ArrowBack/>
                            </IconButton>
                            <p style={{
                                color: '#4CB8DB',
                                fontSize: '25px',
                                fontWeight: 'bold'
                            }}>
                                Order list
                            </p>
                        </div>
                        <Table
                            style={{
                                width: "70vw",
                                overflow: "auto"
                            }}
                            columns={columns}
                            dataSource={state.tableData}
                            pagination={paginationConfig}
                        />
                    </div>
            }
        </>
    );
}

export default UserDetail;