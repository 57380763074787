import Layout from "../pages/Layout";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import UserDetail from "../pages/UserDetail";
import Documents from "../pages/Documents";
import Courses from "../pages/Courses";
import CourseDetail from "../pages/CourseDetail";
import QuizSubmission from "../pages/QuizSubmission";
import CheckList from "../pages/CheckList";
import CheckListSubmission from "../pages/CheckListSubmission";

export default () => {
    return ({
        path: "/dashboard",
        element: <Layout/>,
        children: [
            {
                path: "users",
                children: [
                    {
                        path: "",
                        element: <Users/>,
                    },
                    {
                        path: ":id",
                        element: <UserDetail/>,
                    }
                ]
            },
            {
                path: "Courses",
                children: [
                    {
                        path: "",
                        element: <Courses/>,
                    },
                    {
                        path: ":courseId/lectures",
                        element: <CourseDetail/>,
                        children: [
                            {
                                path: "",
                                element: <CourseDetail/>,
                            },
                            {
                                path: ":lectureId/submissions",
                                element: <QuizSubmission/>,
                            }
                        ]
                    }
                ]
            },
            {
                path: "Documents",
                children: [
                    {
                        path: "",
                        element: <Documents/>,
                    },
                ]
            },
            {
                path: "CheckList",
                children: [
                    {
                        path: "",
                        element: <CheckList/>,
                    },
                    {
                        path: ":checkId/submissions",
                        element: <CheckListSubmission/>,
                    },
                ]
            },
            {
                path: "",
                element: <Dashboard/>,
            },
        ]
    })
}