import {
    CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput
} from '@mui/material';
import {Checkbox, Modal, Table,} from 'antd';
import React, {useEffect, useState} from 'react';
import '../App.css';
import {createCourses, getAllCourses, updateCourses} from '../actions/apis';
import {useNavigate, useParams} from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import {ArrowForward, ArrowForwardIosOutlined, ArrowRight} from "@mui/icons-material";

function Courses() {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [courseDetail, setCourseDetail] = useState({
        name: "", description: "", public: false,
    });
    const showModal = () => {
        setEdit(false)
        setVisible(true);
    };

    const handleOk = async () => {
        try {
            if (edit) {
                await updateCourses({courseId: courseDetail.id, data: courseDetail});
            } else {
                await createCourses({data: courseDetail})
            }
            setVisible(false);
            fetchCoures();
        } catch (e) {
            setVisible(false);
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setCourseDetail({
            name: "", description: "", public: false,
        })
    };
    const columns = [{
        title: "Id", dataIndex: "id", render: (e) => {
            return e || "--"
        }
    }, {
        title: "Title", dataIndex: "name",
    }, {
        title: "Description", dataIndex: "description",
    },

        {
            title: "Action", dataIndex: "id", render: (e, row) => {
                return <>
                    <IconButton onClick={() => {
                        setCourseDetail(row)
                        setEdit(true)
                        setVisible(true)
                    }}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => {
                        navigate(`/dashboard/courses/${row.id}/lectures`)
                    }}>
                        <ArrowForwardIosOutlined/>
                    </IconButton>
                </>
            }
        },]

    const paginationConfig = {
        pageSize: 5, showSizeChanger: false
    };

    const [state, setState] = useState({
        tableData: [], isLoading: true
    })

    const fetchCoures = () => {
        getAllCourses().then(res => {
            setState({
                ...state, tableData: res, isLoading: false
            })
        });
    }

    useEffect(() => {
        fetchCoures();

    }, [])
    return (<>
        {state.isLoading ? <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center'
        }}>
            <CircularProgress/>
        </div> : <div className="mainBody">
            <div style={{
                display: 'flex', justifyContent: "space-between", alignItems: 'center'
            }}>
                <p style={{
                    color: '#4CB8DB', fontSize: '25px', fontWeight: 'bold'
                }}>
                    Courses
                </p>

                <button style={{
                    outline: 'none',
                    background: "#4CB8DB",
                    border: "0",
                    color: 'white',
                    padding: "10px 15px ",
                    borderRadius: "7px"
                }} onClick={showModal}>Create Course
                </button>
            </div>
            <Table
                columns={columns}
                dataSource={state.tableData}
                pagination={paginationConfig}

            />
            <Modal
                title={`${edit ? "Edit" : "Create"} Course`}
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Submit"
                centered
                styles={{
                    body: {
                        display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 0'
                    }
                }}
            >
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Course Title</InputLabel>
                    <OutlinedInput
                        type={'text'}
                        label="Course Title"
                        name={"name"}
                        value={courseDetail.name}
                        onChange={(e) => {
                            setCourseDetail((prevState) => ({...prevState, name: e.target.value}))
                        }}
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Course Description</InputLabel>
                    <OutlinedInput
                        multiline={true}
                        minRows={4}
                        type={'text'}
                        label="Course Description"
                        name={"description"}
                        value={courseDetail.description}
                        onChange={(e) => {
                            setCourseDetail((prevState) => ({...prevState, description: e.target.value}))
                        }}
                    />
                </FormControl>
                <Checkbox checked={courseDetail.public}
                          onChange={(e) => setCourseDetail((prevState) => ({...prevState, public: e.target.checked}))}>
                    Is this course publicly accessible?
                </Checkbox>
            </Modal>
        </div>}
    </>);
}

export default Courses;
